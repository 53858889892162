import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Header from '../../components/header/header';
import { MdOutlineEmail, MdOutlineWhatsapp } from "react-icons/md";

import './contact.css';

const Contact = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header page={"home"} />
      <Container className="text-center" style={{ marginTop: '15vh' }}>
        <h1 className="display-3">{process.env.REACT_APP_NAME}</h1>
        <p className="lead">{t('custom_software_message')}</p>

        <Row className="justify-content-center mt-4">

          <Col xs={12} sm={6} md={4} lg={3} className="mb-3">

            <Button
              variant="outline-success"
              href="https://wa.me/5541995309999"
              target="_blank"
              rel="noopener noreferrer"
              className="w-100"
            >
              {t('whatsApp')} {" 1 "}
              <MdOutlineWhatsapp size={22} />

            </Button>

            <Button
              variant="outline-success"
              href="mailto:alanfelipealiske@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
              className="mt-3 w-100"
            >
              {t('email')}{" 1 "}
              <MdOutlineEmail size={22} />
            </Button>

          </Col>

          <Col xs={12} sm={6} md={4} lg={3} className="mb-3">

            <Button
              variant="outline-success"
              href="https://wa.me/5541984984246"
              target="_blank"
              rel="noopener noreferrer"
              className="w-100"
            >
              {t('whatsApp')}{" 2 "}
              <MdOutlineWhatsapp size={22} />
            </Button>

            <Button
              variant="outline-success"
              href="mailto:vil.raf13@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
              className="mt-3 w-100"
            >
              {t('email')}{" 2 "}
              <MdOutlineEmail size={22} />
            </Button>

          </Col>

        </Row>
      </Container>
    </>
  );
};

export default Contact;
