import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      "id": "ID",
      "welcome": "Welcome to the application",
      "description": "This is a multi-language React application.",
      "login": "Login",
      "en": "English",
      "pt": "Portuguese",
      "es": "Spanish",
      "language": "Language",
      "best_choice": "The best choice for remote access",
      "download_now": "Download Now",
      "download_windows": "Download for Windows",
      "download_linux": "Download for Linux",
      "download_mac": "Download for Mac",
      "email": "E-mail",
      "password": "Password",
      "name": "Name",
      "already_have_an_account": "Already have an account?",
      "login_here": "Login here",
      "register": "Register",
      "confirm_password": "Confirm your password",
      "dont_have_an_account": "Don't have an account?",
      "register_here": "Register here",
      "fill_in_the_email_and_Password_field": "Please fill in the Email and Password fields!",
      "user_created_successfully": "User created successfully!",
      "user_created_error": "Error creating user!",
      "passwords_do_not_match": "Passwords do not match!",
      "enter": "Enter",
      "forgot_password": "Forgot your password?",
      "reset_password": "Reset Password",
      "send_reset_link": "Send reset link",
      "profile": "Profile",
      "update_profile": "Update Profile",
      "logout": "Logout",
      "settings": "Settings",
      "help": "Help",
      "terms_of_service": "Terms of Service",
      "privacy_policy": "Privacy Policy",
      "access_error": "Access error!",
      "delete_equipment": "Delete equipment",
      "cancel": "Cancel",
      "save_changes": "Save Changes",
      "screen": "Screen",
      "connect": "Connect",
      "disconnect": "Disconnect",
      "exit": "Exit",
      "edit_device": "Edit Device",
      "device_name": "Device Name",
      "device_image": "Device Image",
      "no_devices": "No Devices!",
      "no_version": "No version available",
      "confirm_action": "Confirm Action?",
      "confirm": "Confirm",
      "confirmation": "Confirmation",
      "whatsApp": "WhatsApp",
      "custom_software_message": "If you need software for remote access to multiple computers, contact us via one of the WhatsApp numbers below.",
      "contact": "Contact",
    }
  },
  pt: {
    translation: {
      "id": "ID",
      "welcome": "Bem-vindo ao aplicativo",
      "description": "Este é um aplicativo React com múltiplos idiomas.",
      "login": "Login",
      "en": "Inglês",
      "pt": "Português",
      "es": "Espanhol",
      "language": "Idioma",
      "best_choice": "Melhor escolha para acesso remoto",
      "download_now": "Baixe Agora",
      "download_windows": "Baixar para Windows",
      "download_linux": "Baixar para Linux",
      "download_mac": "Baixar para Mac",
      "email": "E-mail",
      "password": "Senha",
      "name": "Nome",
      "already_have_an_account": "Já tem uma conta?",
      "login_here": "Faça login aqui",
      "register": "Registrar",
      "confirm_password": "Confirme sua senha",
      "dont_have_an_account": "Não tem uma conta?",
      "register_here": "Cadastre-se aqui",
      "fill_in_the_email_and_Password_field": "Preencha o campo E-mail e Senha!",
      "user_created_successfully": "Usuário criado com sucesso!",
      "user_created_error": "Erro ao criar usuário!",
      "passwords_do_not_match": "As senhas não coincidem!",
      "enter": "Entrar",
      "forgot_password": "Esqueceu sua senha?",
      "reset_password": "Redefinir Senha",
      "send_reset_link": "Enviar link de redefinição",
      "profile": "Perfil",
      "update_profile": "Atualizar Perfil",
      "logout": "Sair",
      "settings": "Configurações",
      "help": "Ajuda",
      "terms_of_service": "Termos de Serviço",
      "privacy_policy": "Política de Privacidade",
      "access_error": "Erro ao acessar!",
      "delete_equipment": "Excluir equipamento",
      "cancel": "Cancelar",
      "save_changes": "Salvar Alterações",
      "screen": "Tela",
      "connect": "Conectar",
      "disconnect": "Desconectar",
      "exit": "Sair",
      "edit_device": "Editar Dispositivo",
      "device_name": "Nome do Dispositivo",
      "device_image": "Imagem do Dispositivo",
      "no_devices": "Nenhum Dispositivo!",
      "no_version": "Nenhuma versão disponível",
      "confirm_action": "Confirmar Ação?",
      "confirm": "Confirmar",
      "confirmation": "Confirmação",
      "whatsApp": "WhatsApp",
      "custom_software_message": "Se você precisa de um software para acesso remoto em múltiplos computadores, entre em contato através de um dos WhatsApps abaixo.",
      "contact": "Contato",
    }
  },
  es: {
    translation: {
      "id": "ID",
      "welcome": "Bienvenido a la aplicación",
      "description": "Esta es una aplicación React multilingüe.",
      "login": "Iniciar sesión",
      "en": "Inglés",
      "pt": "Portugués",
      "es": "Español",
      "language": "Idioma",
      "best_choice": "La mejor opción para acceso remoto",
      "download_now": "Descargar Ahora",
      "download_windows": "Descargar para Windows",
      "download_linux": "Descargar para Linux",
      "download_mac": "Descargar para Mac",
      "email": "Correo electrónico",
      "password": "Contraseña",
      "name": "Nombre",
      "already_have_an_account": "¿Ya tienes una cuenta?",
      "login_here": "Inicia sesión aquí",
      "register": "Registrarse",
      "confirm_password": "Confirma tu contraseña",
      "dont_have_an_account": "¿No tienes una cuenta?",
      "register_here": "Regístrate aquí",
      "fill_in_the_email_and_Password_field": "¡Completa los campos de correo y contraseña!",
      "user_created_successfully": "¡Usuario creado con éxito!",
      "user_created_error": "¡Error al crear el usuario!",
      "passwords_do_not_match": "¡Las contraseñas no coinciden!",
      "enter": "Entrar",
      "forgot_password": "¿Olvidaste tu contraseña?",
      "reset_password": "Restablecer Contraseña",
      "send_reset_link": "Enviar enlace de restablecimiento",
      "profile": "Perfil",
      "update_profile": "Actualizar Perfil",
      "logout": "Cerrar sesión",
      "settings": "Configuraciones",
      "help": "Ayuda",
      "terms_of_service": "Términos del Servicio",
      "privacy_policy": "Política de Privacidad",
      "access_error": "¡Error de acceso!",
      "delete_equipment": "Eliminar equipo",
      "cancel": "Cancelar",
      "save_changes": "Guardar Cambios",
      "screen": "Pantalla",
      "connect": "Conectar",
      "disconnect": "Desconectar",
      "exit": "Salir",
      "edit_device": "Editar Dispositivo",
      "device_name": "Nombre del Dispositivo",
      "device_image": "Imagen del Dispositivo",
      "no_devices": "¡No hay dispositivos!",
      "no_version": "No hay versión disponible",
      "confirm_action": "¿Confirmar acción?",
      "confirm": "Confirmar",
      "confirmation": "Confirmación",
      "whatsApp": "WhatsApp",
      "custom_software_message": "Si necesitas un software para acceso remoto a múltiples computadoras, contáctanos a través de uno de los números de WhatsApp abajo.",
      "contact": "Contacto",
    }
  }
};


i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
