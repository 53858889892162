import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ConfirmDialog = ({ message, onConfirm }) => {
  const { t, i18n } = useTranslation();

  return (
    <Modal show={true} onHide={() => onConfirm(false)} keyboard={false}>
      <Modal.Header closeButton className="bg-dark text-white">
        <Modal.Title>Confirmação</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-dark text-white">{message}</Modal.Body>
      <Modal.Footer className="bg-dark">
        <Button variant="secondary" size="sm" onClick={() => onConfirm(false)}>
          {t('cancel')}
        </Button>
        <Button variant="warning" size="sm" onClick={() => onConfirm(true)}>
          {t('confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDialog;
