const useAuth = () => {
    try {
        const storedUser = localStorage.getItem('user');
        
        if (!storedUser) return false;
        
        const user = JSON.parse(storedUser);
        
        return user?.token ? true : false;
    } catch (error) {
        console.error('Erro ao verificar autenticação:', error);
        return false;
    }
};

export default useAuth;
