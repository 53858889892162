import React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Navbar from 'react-bootstrap/Navbar';
import { Offcanvas } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import UserConfigs from '../../../components/userConfigs/userConfigs';
import './header.css';

const Header = ({
  handleSendConnect,
  password,
  setPassword,
  roomId,
  setRoomId,
  connectionDevice,
  handleChangeMonitor,
  monitoCount,
  selectedMonitor,
  handleDisconnect
}) => {
  const { t } = useTranslation();
  const maxMonitors = Math.min(monitoCount, 3);

  return (
    <Navbar
      bg="dark"
      expand="lg"
      data-bs-theme="dark"
      className="bg-body-tertiary p-0"
    >
      <Container fluid>
        <Navbar.Brand className='title-connect-devices'>
          {process.env.REACT_APP_NAME}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="offcanvasNavbar" />
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
          className="bg-dark text-light"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel">
              {process.env.REACT_APP_NAME}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className='d-flex align-items-center flex-column flex-lg-row ms-auto'>
              {Array.from({ length: maxMonitors }, (_, index) => (
                <Button
                  key={index}
                  size="sm"
                  className="m-1"
                  disabled={selectedMonitor === index + 1}
                  variant={selectedMonitor === index + 1 ? "secondary" : "outline-secondary"}
                  onClick={() => handleChangeMonitor(index + 1)}
                >
                  {t('screen')}{`${index + 1}`}
                </Button>
              ))}

              <InputGroup size='sm' className="m-1">
                <InputGroup.Text className="bg-dark text-light">{t('id')}</InputGroup.Text>
                <Form.Control
                  type='text'
                  placeholder={t('id')}
                  value={roomId}
                  maxLength={15}
                  onChange={(e) => setRoomId(e.target.value)}
                  className="bg-dark text-light border-secondary"
                />
              </InputGroup>

              <InputGroup size='sm' className="m-1">
                <InputGroup.Text className="bg-dark text-light">{t('password')}</InputGroup.Text>
                <Form.Control
                  type='text'
                  placeholder={t('password')}
                  value={password}
                  maxLength={15}
                  onChange={(e) => setPassword(e.target.value.toLowerCase())}
                  className="bg-dark text-light border-secondary"
                />
              </InputGroup>

              {connectionDevice ? (
                <Button
                  size='sm'
                  className="m-1"
                  variant='outline-warning'
                  onClick={handleDisconnect}
                >
                  {t('disconnect')}
                </Button>
              ) : (
                <Button
                  size='sm'
                  className="m-1"
                  variant='success'
                  onClick={handleSendConnect}
                >
                  {t('connect')}
                </Button>
              )}

              <UserConfigs />
            </div>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default Header;
