import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Header from '../../components/header/header';
import Api from '../../services/Api'
import './home.css';

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [softwareVersions, setSoftwareVersions] = useState({})

  const handleDownload = (platform) => {
    window.open(`${process.env.REACT_APP_API_PCLINKER}/api/v1/download-app/${platform}`);
  };

  const handleLastVersions = async () => {
    try {
      const { data } = await Api.get(`/api/v1/last-versions`);
      setSoftwareVersions(data)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleLastVersions();
  }, [])

  return (
    <>
      <Header page={"home"} />
      <Container className="text-center" style={{ marginTop: '15vh' }}>
        <h1 className="display-3">{process.env.REACT_APP_NAME}</h1>
        <p className="lead">{t('best_choice')}</p>
        <Row className="justify-content-center mt-4">
          <Col xs={12} sm={6} md={4} lg={3} className="mb-3">
            <Button
              variant="success"
              onClick={() => handleDownload('windows')}
              size="lg"
              className="w-100"
            >
              {t('download_windows')}
              <br />
              <span className="small">{softwareVersions?.windows || "--"}</span>
            </Button>
          </Col>

          <Col xs={12} sm={6} md={4} lg={3} className="mb-3">
            <Button
              variant="success"
              onClick={() => handleDownload('linux')}
              size="lg"
              className="w-100"
            >
              {t('download_linux')}
              <br />
              <span className="small">{softwareVersions?.linux || "--"}</span>
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Home;
