import Image from "react-bootstrap/Image";
import './img.css'

const Img = ({ imageSrc, handleMouseEnter, handleMouseLeave, handleMouseMove, handleImageClick, }) => {

  return (
    <div className="screen-container mt-2">
      <div className="screen">
        {
          imageSrc ? (
            <Image
              className="img"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onMouseMove={handleMouseMove}
              onClick={handleImageClick}
              onContextMenu={handleImageClick}
              src={imageSrc}
              draggable="false"
            />
          ) : (
            "Aguardando imagem..."
          )}
      </div>
    </div>
  );
};

export default Img;
