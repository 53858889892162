import React, { createContext, useEffect, useState, useContext } from 'react';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const [, setIsConnected] = useState(false);
    const [reconnectAttempts, setReconnectAttempts] = useState(0);
    const maxReconnectAttempts = 5;

    useEffect(() => {
        const connectWebSocket = () => {
            const ws = new WebSocket(process.env.REACT_APP_API_PCLINKER.replace('https', 'wss').replace('http', 'ws'));

            let pingInterval;

            ws.onopen = () => {
                console.log('WebSocketProvider conectado.');
                setSocket(ws);
                setIsConnected(true);
                setReconnectAttempts(0); // Resetar tentativas de reconexão

                pingInterval = setInterval(() => {
                    if (ws.readyState === WebSocket.OPEN) {
                        ws.send(JSON.stringify({ type: 'ping' }));
                    }
                }, 15000);
            };

            ws.onerror = (error) => {
                console.error('Erro no WebSocket:', error);
            };

            ws.onclose = (event) => {
                console.log(`WebSocket desconectado: ${event.code} - ${event.reason}`);
                setIsConnected(false)
                clearInterval(pingInterval);

                if (reconnectAttempts < maxReconnectAttempts) {
                    const timeout = Math.pow(2, reconnectAttempts) * 1000; // Exponencial Backoff
                    setReconnectAttempts(prev => prev + 1); // Incrementa o número de tentativas de reconexão
                    setTimeout(connectWebSocket, timeout);
                } else {
                    console.error('Máximo de tentativas de reconexão alcançado.');
                }
            };

            return () => {
                if (ws.readyState === WebSocket.OPEN) {
                    clearInterval(pingInterval);
                    ws.close(1000, 'Componente desmontado');
                }
            };
        }
        connectWebSocket();

        return () => {
            if (socket && socket.readyState === WebSocket.OPEN) {
                socket.close(1000, 'Componente desmontado');
            }
        };
    }, []);

    return (
        <WebSocketContext.Provider value={socket}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => useContext(WebSocketContext);
