import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import { FaCircle } from "react-icons/fa";
import { IoSettings } from "react-icons/io5";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import Image from "react-bootstrap/Image";
import './imgDevices.css';

const ImgDevices = ({ device, statusDevices, handleConnectDevice, handleEditDevice, handleDeleteDevice }) => {
  const { t, i18n } = useTranslation();

  const isDeviceOnline = statusDevices.some(statusDevice => statusDevice.room === device.id && statusDevice.status === "connected" && statusDevice.identifier === "device");

  const [showModal, setShowModal] = useState(false);
  const [newName, setNewName] = useState(device.name);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleSaveChanges = () => {
    handleEditDevice(device.id, newName);
    handleCloseModal();
  };

  const handleDelete = () => {
    handleDeleteDevice(device.id);
    handleCloseModal();
  };

  return (
    <>
      <Card className="device-card text-white bg-dark mt-2" title={t('connect')} onClick={() => handleConnectDevice(device.password, device.id)}>
        {device.img_b64 && device.img_b64.startsWith('data:image/') && (
          <div className="device-image-container">
            <Image
              className="device-image"
              src={device.img_b64}
              alt={t('device_image')}
              fluid
            />
          </div>
        )}
        <Card.Body className="d-flex flex-column justify-content-end">
          <div>
            <p className="m-0 p-0">{device.name || "--"}</p>
          </div>
          <div className="device-footer d-flex justify-content-between align-items-center">
            <FaCircle size={20} color={isDeviceOnline ? "green" : "gray"} />
            <span className="device-id">{device.id}</span>
            <IoSettings
            title="Configs"
              className="icon-config"
              color="gray"
              size={20}
              onClick={(e) => {
                e.stopPropagation();
                handleShowModal();
              }} />
          </div>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={handleCloseModal} className="dark-modal">
        <Modal.Header closeButton className="bg-dark text-white">
          <Modal.Title>{t('edit_device')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark text-white">
          <Form>
            <Form.Group controlId="formDeviceName">
              <Form.Label>{t('device_name')}</Form.Label>
              <Form.Control
                type="text"
                size="sm"
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                className="bg-dark text-white"
                maxLength={35}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="bg-dark">
          <Button variant="danger" size="sm" onClick={handleDelete}>
            {t('delete_equipment')}
          </Button>
          <Button variant="secondary" size="sm" onClick={handleCloseModal}>
            {t('cancel')}
          </Button>
          <Button variant="success" size="sm" onClick={handleSaveChanges}>
            {t('save_changes')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImgDevices;
