import { useCallback } from 'react';

function useClearLocalStorage() {
  // Hook para limpar valores do localStorage
  const clearStorage = useCallback((keys = []) => {
    if (!Array.isArray(keys)) {
      console.error("O argumento fornecido não é um array");
      return;
    }

    keys.forEach(key => {
      localStorage.removeItem(key);
    });
  }, []);

  return clearStorage;
}

export default useClearLocalStorage;
