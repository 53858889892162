import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container, Navbar, Nav, Button, NavDropdown, Offcanvas } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

const Header = (props) => {
  const { page } = props;
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    document.documentElement.lang = i18n.language;
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", t('description'));
    }
  }, [i18n.language, t]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleNavigate = (page) => {
    navigate(`/${page}`)
  }

  const handleLoginRedirect = () => {
    navigate('/login');
  };

  return (
    <Navbar
      bg="dark"
      expand="lg"
      data-bs-theme="dark"
      className="bg-body-tertiary p-0"
    >
      <Container fluid>
        <Navbar.Brand href="/" className='title-connect-devices'>
          {process.env.REACT_APP_NAME}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-lg`}
          aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
          placement="end"
          className="bg-dark text-light"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
              {process.env.REACT_APP_NAME}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-2">
              <NavDropdown
                className="ms-2"
                title={t('language')}
                id={`offcanvasNavbarDropdown-expand-lg`}
              >

                <NavDropdown.Item onClick={() => changeLanguage('en')}>
                  {t('en')}
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => changeLanguage('es')}>
                  {t('es')}
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => changeLanguage('pt')}>
                  {t('pt')}
                </NavDropdown.Item>
              </NavDropdown>

              {page === 'home' && (
                <Button
                  className="ms-2"
                  variant="warning"
                  onClick={handleLoginRedirect}
                >
                  {t('login')}
                </Button>
              )}

              <Button
                className="ms-2"
                variant="secondary"
                onClick={() => handleNavigate('contact')}
              >
                {t('contact')}
              </Button>

            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default Header;
