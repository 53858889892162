import React, { useState } from "react";
import { Form, Button, Container, Row, Col, FloatingLabel } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Api from "../../services/Api"
import './register.css'
import Header from "../../components/header/header";

const Register = () => {

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async () => {
    if (!email | !password) {
      setError(t('fill_in_the_email_and_Password_field'));
      return;
    } else {
      handleSignup()
    }
  }

  const handleSignup = async () => {
    try {

      var { data } = await Api.post("/api/v1/create-user", {
        name: name,
        email: email,
        password: password
      })
      console.log(data);
      localStorage.setItem('user', JSON.stringify(data));
      alert(t('user_created_successfully'))
      navigate("/login");

    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        alert(error?.response?.data?.message)
      } else {
        alert(t('user_created_error'))
      }
    }
  };

  const handleRegister = () => {
    if (password !== confirmPassword) {
      setError(t('passwords_do_not_match'));
      return;
    }
    setError('');
    handleLogin();
  };

  return (
    <>
      <Header />
      <Container fluid className="d-flex p-0"  style={{marginTop: "15vh"}}>
        <Row className="w-100 m-0">
          <Col className="d-flex align-items-center justify-content-center p-4">
            <div className="div-register">
              <h2 className="text-center my-4">{t('register')}</h2>
              <FloatingLabel controlId="formBasicName" label={t('name')} className="mb-3">
                <Form.Control
                  type="text"
                  placeholder={t('name')}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </FloatingLabel>

              <FloatingLabel controlId="formBasicEmail" label={t('email')} className="mb-3">
                <Form.Control
                  type="email"
                  placeholder={t('email')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </FloatingLabel>

              <FloatingLabel controlId="formBasicPassword" label={t('password')} className="mb-3">
                <Form.Control
                  type="password"
                  placeholder={t('password')}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </FloatingLabel>

              <FloatingLabel controlId="formConfirmPassword" label={t('confirm_password')}>
                <Form.Control
                  type="password"
                  placeholder={t('confirm_password')}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </FloatingLabel>

              <Button
                variant="success"
                className="mt-3 w-100"
                size="lg"
                onClick={() => { handleRegister() }}
              >
                {t('register')}
              </Button>

              {error && <p className="msg-error">{error}</p>}
              <div className="mt-3 text-center">
                <p>
                  {t('already_have_an_account')} <Link to="/login">{t('login_here')}</Link>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Register;
